






















import { Component, Mixins } from 'vue-property-decorator'
import { Card, DataTable, DataTableColumn, TagBox, Action, HttpGet, HttpPost, Searchbox } from '../../lib/mavejs'
import { url } from '@/functions'
import { SearchPaginationResetMixin } from '@/mixins/SearchPaginationResetMixin'

@Component({
  components: { Card, DataTable, DataTableColumn, TagBox, Action, HttpGet, HttpPost, Searchbox },
  methods: { url }
})
export default class Index extends Mixins(SearchPaginationResetMixin) {
  page = 1
  search = ''
  changed = [];

  filters = {
    search: { column: 'name', value: '' }
  }

  detect (row) {
    this.changed = this.changed.filter(item => item.id !== row.id)
    this.changed.push(row)

    this.$forceUpdate()
  }

  paginate (currentPage) {
    this.page = currentPage
  }
}
